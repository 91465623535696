import React from 'react';
import './ComoLlegar.css';
import {useSpring, animated, config } from 'react-spring';
import mapa from './images/Mapa.jpg'

function ComoLlegar() {
    const Animation1 = useSpring({ config: config.molasses, delay: 1000, opacity: 1, from: { opacity: 0}})
    const Animation2 = useSpring({ config: config.molasses, delay: 1500, opacity: 1, from: { opacity: 0}})

    return (
        <div className="comoLlegar">
            <animated.div style={Animation1} className="comoLlegar__title">
                <h1 className="comoLlegar__title">
                    COMO LLEGAR
                </h1>
            </animated.div>
            <animated.div  style={Animation2} className="comoLlegar__info">              
                <div className="comoLlegar__data">               
                    <h3>
                        Lechuza y Avenida Juan B. Justo
                    </h3>
                <br/>
                <h4>
                    Coloca en  <a href="https://www.waze.com/es/livemap/directions/argentina/provincia-de-buenos-aires/tandil/cabanas-del-arroyo?place=ChIJU_wxTysfkZURG8O59MsLIn8">WAZE</a> o <a href="https://www.google.com/maps/place/Caba%C3%B1as+del+Arroyo/@-37.3419969,-59.186489,15z/data=!4m5!3m4!1s0x0:0x7f220bcbf4b9c31b!8m2!3d-37.3419969!4d-59.186489">Google Maps </a>
                    “Lechuza y Avenida Juan B Justo (también conocida como Av. Jujuy).
                    Al llegar a la esquina girar por Lechuza (única opción ya que nace allí la calle) avanzar 2
                    cuadras, girar a la derecha y al final de la calle sobre mano izquierda se encuentra la tranquera
                    de acceso al predio                
                </h4>
                </div>
                <div className="comoLlegar__image">
                    <a href="https://www.google.com/maps/place/Caba%C3%B1as+del+Arroyo/@-37.3419969,-59.186489,15z/data=!4m5!3m4!1s0x0:0x7f220bcbf4b9c31b!8m2!3d-37.3419969!4d-59.186489">
                        <img src={mapa} alt="Mapa"/>
                    </a>
                </div>
            </animated.div>
        </div>
    )
}

export default ComoLlegar
