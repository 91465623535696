import React from 'react';
import NosotrosEachLeft from './Components/NosotrosEachLeft';
import NosotrosEachRight from './Components/NosotrosEachRight';
import './Nosotros.css';
import image1 from './images/Optimizadas/Exterior3.jpg';
import image2 from './images/Optimizadas/Exterior1.jpg'
import image3 from './images/Optimizadas/Exterior7.jpg';
import image4 from './images/Optimizadas/Exterior5.jpg';
import {useSpring, animated, config } from 'react-spring'


const description1 = "Ubicado en medio de un bosque y atravesado por pequeño curso de agua, se encuentra Cabañas del Arroyo. Este emprendimiento familiar te ofrece todo lo que necesitas para desconectarte de las urgencias de la rutina, recuperarte del estrés de la ciudad y disfrutar de la naturaleza."
const description2 = "Situadas a 5 km del centro de Tandil, en un espacio de quietud, rodeadas de verde, encontrarás las cabañas con las comodidades y el equipamiento necesario para que disfrutes de tu estadía."
const description3 = "Durante el día podrás hacer caminatas en el predio, relajarte en los amplios decks o tomar baños de agua y sol en la piscina mientras escuchas el canto de los pájaros. Si preferís, también podrás realizar paseos cercanos como el Cerro Centinela,  La Movediza o el Fuerte Independencia entre otras muchas atracciones que ofrece la ciudad."
const description4 = "Por las noches, la vista del cielo estrellado es una gran opción, aunque por supuesto, la noche tandilense cuenta con excelentes alternativas gastronómicas por conocer."

function Nosotros() {
    const nosotrosAnimation1 = useSpring({ config: config.molasses, delay: 1000, opacity: 1, from: { opacity: 0}})
    const nosotrosAnimation2 = useSpring({ config: config.molasses, delay: 1500, opacity: 1, from: { opacity: 0}})
    const nosotrosAnimation3 = useSpring({ config: config.molasses, delay: 2000, opacity: 1, from: { opacity: 0}})
    const nosotrosAnimation4 = useSpring({ config: config.molasses, delay: 2500, opacity: 1, from: { opacity: 0}})
    const nosotrosAnimation5 = useSpring({ config: config.molasses, delay: 3000, opacity: 1, from: { opacity: 0}})
    const nosotrosAnimation6 = useSpring({ config: config.molasses, delay: 3500, opacity: 1, from: { opacity: 0}})

    return (
        <div className="nosotros">
            
            <animated.div style={nosotrosAnimation1} className="nosotros__title">
                <h1 className="nosotros__title">
                    DEL ARROYO
                </h1>
            </animated.div>

            <animated.div style={nosotrosAnimation2} className="nosotros__description">
               <NosotrosEachLeft image={image1} description={description1}/>
            </animated.div>

            <animated.div style={nosotrosAnimation3} className="nosotros__description">
                <NosotrosEachRight image={image2} description={description2}/>
            </animated.div>

            <animated.div style={nosotrosAnimation4} className="nosotros__description">
              <NosotrosEachLeft image={image3} description={description3}/>
            </animated.div>
            
            <animated.div style={nosotrosAnimation5} className="nosotros__description">
                <NosotrosEachRight image={image4} description={description4}/>
            </animated.div>

            <animated.div style={nosotrosAnimation6} className="nosotros__description2">
                <h3 className="nosotros__description2">

                Te esperamos para darte la mejor atención. Nuestro objetivo es hacer de tu estadía en 
                Tandil la mejor experiencia. <br /><br />

                Nos preparamos para recibirte con calidez.
                </h3>            
            </animated.div>

            

        </div>
    )
}

export default Nosotros
