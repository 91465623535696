import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { List, ListItem, ListItemText, Container, Button, Typography, Toolbar, AppBar, Hidden } from "@material-ui/core"
import { NavLink } from 'react-router-dom';
import SideDrawer from './SideDrawer';
import './Navbar.css';


const navbarLinks = [
  { title: `NOSOTROS`, path: `/about-us` },
  { title: `EL LUGAR`, path: `/gallery` },
  { title: `SERVICIOS`, path: `/services` },
  { title: `CONTACTO`, path: `/contact` },
  { title: `COMO LLEGAR`, path: `/address` }
]

const useStyles = makeStyles((theme) => ({
  navbar: {
    backgroundColor: '#284e47',
    familyFont: 'Quicksand',
  },
  navbarDisplayFlex: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  navDisplayFlex: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  linkText: {
    familyFont: 'Quicksand',
    textDecoration: 'none',
    color: '#e4d9bd',
    '&:hover':{
      color: '#e4d9bd'
    },
    '&.active':{
      color: '#e4d9bd',
      borderBottom: '2px solid #e4d9bd',
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  logo: {
    fontFamily: 'Bilanesa',
    fontSize:'2rem',
    textTransform: 'Capitalize',
    flexGrow: 1,
    color: '#e4d9bd',
    textDecoration: 'none',
    display: 'flex',
    alignItems: 'center',
    '&:hover':{
      color: '#e4d9bd'
    },
  },
}));

function Navbar() {
  const classes = useStyles();

   
    return (
      <div className={classes.root}>
      <AppBar position="static" className={classes.navbar}>
        <Toolbar>
          <Container className={classes.navbarDisplayFlex}>
            <div className={classes.logo}>
              <NavLink to={"/"} className={classes.logo}>
                <Button>
                  <Typography variant="h6" className={classes.logo}>
                    Del Arroyo
                  </Typography>
                </Button>
              </NavLink>
            </div>
            <Hidden smDown> 
            <div className="navbar__links">
              <List component="nav" aria-labelledby="main navigation" className={classes.navDisplayFlex}>
                {navbarLinks.map(({ title, path }) => (
                  <NavLink to={path} key={title} className={classes.linkText}>
                    <ListItem button>
                      <ListItemText primary={title} />
                    </ListItem>
                  </NavLink>
                ))}
              </List>
            </div>
            </Hidden>
            <Hidden mdUp>
            <SideDrawer navbarLinks={navbarLinks}  />
            </Hidden>
          </Container>
        </Toolbar>
      </AppBar>
    </div>
    )
}

export default Navbar
