import React, { useState} from 'react'
import { Tab, Tabs} from '@material-ui/core';
import PropTypes from 'prop-types';
import './ElLugar.css';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css'; 

// Exteriores
import Exterior1 from './images/Optimizadas/Exterior1.jpg';
import Exterior2 from './images/Optimizadas/Exterior2.jpg';
import Exterior3 from './images/Optimizadas/Exterior3.jpg';
import Exterior4 from './images/Optimizadas/Exterior4.jpg';
import Exterior5 from './images/Optimizadas/Exterior5.jpg';
import Exterior6 from './images/Optimizadas/Exterior6.jpg';
import Exterior7 from './images/Optimizadas/Exterior7.jpg';
import Exterior8 from './images/Optimizadas/Exterior8.jpg';
import Exterior9 from './images/Optimizadas/Exterior9.jpg';
import Exterior10 from './images/Optimizadas/Exterior10.jpg';
import Exterior11 from './images/Optimizadas/Exterior11.jpg';
import Exterior12 from './images/Optimizadas/Exterior12.jpg';

// Interiores
import Interior1 from './images/Optimizadas/Interior1.jpg';
import Interior2 from './images/Optimizadas/Interior2.jpg';
import Interior3 from './images/Optimizadas/Interior3.jpg';
import Interior4 from './images/Optimizadas/Interior4.jpg';
import Interior5 from './images/Optimizadas/Interior5.jpg';
import Interior6 from './images/Optimizadas/Interior6.jpg';
import Interior7 from './images/Optimizadas/Interior7.jpg';
import Interior8 from './images/Interiores6.jpeg';
import Interior9 from './images/Interiores4.jpeg';


const exteriores = [ Exterior1, Exterior2, Exterior3, Exterior4, Exterior5, Exterior6, Exterior7,
    Exterior8, Exterior9, Exterior10, Exterior11, Exterior12 ]

const interiores = [ Interior1, Interior2, Interior3, Interior4, Interior5,
    Interior6, Interior7, Interior8, Interior9 ]

function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
        className="image__cont"
      >
        {value === index && (
            <div className="image__container">
                 {children}
          </div>
        )}
      </div>
    );
  }
  
  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };
  
  
function ElLugar() {
    const [value, setValue] = useState(0);
    const [ photoIndexExt, setPhotoIndexExt ] = useState(0);
    const [ isOpenExt, setIsOpenExt ] = useState(false);
    const [ photoIndexInt, setPhotoIndexInt ] = useState(0);
    const [ isOpenInt, setIsOpenInt ] = useState(false);

    const handleChange = (event, newValue) => {
        setValue(newValue);
      };

    const handleLightboxExt = (idx) => {
        setIsOpenExt(true);
        setPhotoIndexExt(idx)
    }

    const handleLightboxInt = (idx) => {
        setIsOpenInt(true);
        setPhotoIndexInt(idx)
    }

    console.log(photoIndexInt)
   
    return (
        <div className="elLugar">
            <Tabs
                value={value}
                onChange={handleChange}
                textColor='primary'
                centered
            >
                <Tab label="EL PREDIO" />
                <Tab label="LAS CABAÑAS" />
            </Tabs>
            <TabPanel value={value} index={0}>
                {exteriores.map( (i, idx) => <img src={i} alt="Exteriores" key={i} onClick={() => handleLightboxExt(idx)}/>)}
            </TabPanel>
            <TabPanel value={value} index={1}>
                {interiores.map( (i, idx) => <img src={i} alt="Interiores" key={i} onClick={() => handleLightboxInt(idx)}/>)}
            </TabPanel>

            {isOpenExt && (
            <Lightbox
                mainSrc={exteriores[photoIndexExt]}
                nextSrc={exteriores[(photoIndexExt + 1) % exteriores.length]}
                prevSrc={exteriores[(photoIndexExt + exteriores.length - 1) % exteriores.length]}
                onCloseRequest={() => setIsOpenExt(false)}
                onMovePrevRequest={() =>
                     setPhotoIndexExt((photoIndexExt + exteriores.length - 1) % exteriores.length)
                  }  
                onMoveNextRequest={() => setPhotoIndexExt((photoIndexExt +1) % exteriores.length)}
            />
            )}

            {isOpenInt && (
            <Lightbox
                mainSrc={interiores[photoIndexInt]}
                nextSrc={interiores[(photoIndexInt + 1) % interiores.length]}
                prevSrc={interiores[(photoIndexInt + interiores.length - 1) % interiores.length]}
                onCloseRequest={() => setIsOpenInt(false)}
                onMovePrevRequest={() =>
                     setPhotoIndexInt((photoIndexInt + interiores.length - 1) % interiores.length)
                  }  
                onMoveNextRequest={() => setPhotoIndexInt((photoIndexInt +1) % interiores.length)}
            />
            )}
    </div>
    )
}

export default ElLugar
