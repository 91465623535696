import React from 'react';
import './NosotrosEachRight.css';

function NosotrosEachRight({ image, description }) {
    return (
        <div className='nosotrosEachRight'>
            <h3 className="nosotros__EachRightdescription">
               {description}
            </h3>
            <img src={image} alt='Nosotros' />
        </div>
    )
}

export default NosotrosEachRight
