import React from 'react';
import background from './images/Optimizadas/Portada3.jpg';
import './Home.css';

function Home() {

    return (
        <div  className="home" style={{ backgroundImage: `url(${background})`}}>
        </div>
    )
}

export default Home
