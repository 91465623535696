import React from 'react';
import './Servicios.css'
import {useSpring, animated, config } from 'react-spring';
import AcUnitIcon from '@material-ui/icons/AcUnit';
import WifiIcon from '@material-ui/icons/Wifi';
import Servicio from './Components/Servicio';
import TvIcon from '@material-ui/icons/Tv';
import HotelIcon from '@material-ui/icons/Hotel';
import KitchenIcon from '@material-ui/icons/Kitchen';
import FireplaceIcon from '@material-ui/icons/Fireplace';
import OutdoorGrillIcon from '@material-ui/icons/OutdoorGrill';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';
import AlarmOnIcon from '@material-ui/icons/AlarmOn';
import PoolIcon from '@material-ui/icons/Pool';

const services = [ {
    name: 'PILETA',
    icon: <PoolIcon style={{ fontSize: 100 }} />,
},{
    name: 'WIFI',
    icon: <WifiIcon style={{ fontSize: 100 }}  />,
},{
    name: 'DIRECT TV',
    icon: <TvIcon style={{ fontSize: 100 }}  />,
},{
    name: 'ROPA BLANCA',
    icon: <HotelIcon style={{ fontSize: 100 }}  />,
},{
    name: 'COCINA EQUIPADA',
    icon: <KitchenIcon style={{ fontSize: 100 }}  />,
},{
    name: 'CALEFACCIÓN',
    icon: <FireplaceIcon style={{ fontSize: 100 }} />,
},{
    name: 'PARRILLA INDIVIDUAL',
    icon: <OutdoorGrillIcon style={{ fontSize: 100 }}  />,
},{
    name: 'AIRE ACONDICIONADO',
    icon: <AcUnitIcon style={{ fontSize: 100 }} />,
},{
    name: 'ALARMA',
    icon: <NotificationsActiveIcon style={{ fontSize: 100 }}  />,
},{
    name: 'DETECTORES DE HUMO',
    icon: <AlarmOnIcon style={{ fontSize: 100 }}  />,
}]

function Servicios() {
    const Animation1 = useSpring({ config: config.molasses, delay: 1000, opacity: 1, from: { opacity: 0}})
    const Animation2 = useSpring({ config: config.molasses, delay: 1500, opacity: 1, from: { opacity: 0}})

    return (
        <div className='servicios'>
             <animated.div style={Animation1} className="servicios__title">
                <h1 className="servicios__title"> 
                    SERVICIOS
                </h1>
            </animated.div>
            <animated.div style={Animation2} className="servicios__all">
              {services.map(s => <Servicio name={s.name} icon={s.icon} />)}
            </animated.div>

        </div>
    )
}

export default Servicios
