import React from 'react';
import './Servicio.css'

function Servicio({name, icon}) {
    return (
        <div className="servicio">
            {icon}
            <h3 className="servicio__name" >
                {name}
            </h3>   
        </div>
    )
}

export default Servicio
