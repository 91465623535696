import './App.css';
import Navbar from './Components/Navbar';
import { BrowserRouter, Switch, Route } from 'react-router-dom'
import Home from './Home'
import ElLugar from './ElLugar';
import Nosotros from './Nosotros';
import Servicios from './Servicios';
import Contacto from './Contacto';
import ComoLlegar from './ComoLlegar';

function App() {
  return (
    <div className="app">
      <BrowserRouter>
        <Navbar />
        <Switch>
          <Route exact path="/">
           <Home />
          </Route>
          <Route exact path="/about-us">
           <Nosotros />
          </Route>
          <Route exact path="/gallery">
           <ElLugar />
          </Route>
          <Route exact path="/services">
           <Servicios />
          </Route>
          <Route exact path="/contact">
           <Contacto />
          </Route>
          <Route exact path="/address">
           <ComoLlegar />
          </Route>
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
