import React from 'react';
import './NosotrosEachLeft.css'

function NosotrosEachLeft( { image, description }) {
    return (
        <div className='nosotrosEachLeft'>
            <img src={image} alt='Nosotros' />
            <h3 className="nosotros__EachLeftdescription">
               {description}
            </h3>
        </div>
    )
}

export default NosotrosEachLeft
