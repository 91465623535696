import React from 'react';
import './Contacto.css';
import image from './images/Optimizadas/Exterior4.jpg';
import InstagramIcon from '@material-ui/icons/Instagram';
import FacebookIcon from '@material-ui/icons/Facebook';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import EmailIcon from '@material-ui/icons/Email';
import {useSpring, animated, config } from 'react-spring';

function Contacto() {
    const Animation1 = useSpring({ config: config.molasses, delay: 1000, opacity: 1, from: { opacity: 0}})
    const Animation2 = useSpring({ config: config.molasses, delay: 1500, opacity: 1, from: { opacity: 0}})

    return (
        <div className="contacto">
            <animated.div style={Animation1} className="contacto__title">
                <h1 className="contacto__title">
                    CONTACTO
                </h1>
            </animated.div>
            <animated.div  style={Animation2} className="contacto__info">
                <div className="contacto__image">
                    <img src={image} alt="Contact"/>
                </div>
                <div className="contacto__data">               
                    <p><WhatsAppIcon /> +549 249 4277810 </p>               
                    <p><EmailIcon /> delarroyotandil@gmail.com</p>
                    <p><InstagramIcon /> <a href="https://www.instagram.com/delarroyotandil/"> /delarroyotandil</a> </p>
                    <p><FacebookIcon /> <a href="https://www.facebook.com/delarroyotandil/"> /delarroyotandil</a> </p>          
                </div>
            </animated.div>
        </div>
    )
}

export default Contacto
