import React, { useState } from 'react';
import { IconButton, List, ListItem, ListItemText, Drawer } from "@material-ui/core";
import { Menu } from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";
import { NavLink } from 'react-router-dom';
import './Navbar.css';

const useStyles = makeStyles({
    list: {
      width: '100vw',
      height: '100vh',
      backgroundColor: '#284e47',
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      justifyContent: 'space-around',
      fontFamily: 'Quicksand'
    },
    linkText: {
      fontFamily: 'Quicksand',
      textDecoration: 'none',
      color: '#e4d9bd',
    '&:hover':{
      color: '#fcf6ac'
    },
    },
    menu: {
        color: '#e4d9bd',
        fontFamily: 'Quicksand'
    },
    drawer:{
        backgroundColor: '#022406',
        fontFamily: 'Quicksand'
    }
  })

function SideDrawer( { navbarLinks }) {
    const [state, setState] = useState({ right: false })
    const classes = useStyles();

    const toggleDrawer = (anchor, open) => event => {
        if (
          event.type === "keydown" &&
          (event.key === "Tab" || event.key === "Shift")
        ) {
          return
        }
        setState({ [anchor]: open })
      }
    
    const sideDrawerList = anchor => (
        <div
          role="presentation"
          onClick={toggleDrawer(anchor, false)}
          onKeyDown={toggleDrawer(anchor, false)}
        >
          <List component="nav" aria-labelledby="main navigation" className={classes.list}>
                {navbarLinks.map(({ title, path }) => (
                  <NavLink to={path} key={title} className={classes.linkText}>
                    <ListItem button>
                      <ListItemText primary={title} />
                    </ListItem>
                  </NavLink>
                ))}
              </List>
        </div>
      );

    return (
        <div className="sidedrawer">
            <IconButton edge="start" aria-label="menu"
            onClick={toggleDrawer("right", true)}  
            >
                <Menu className={classes.menu}/>
            </IconButton>
            <Drawer
                anchor="right"
                open={state.right}
                onOpen={toggleDrawer("right", true)}
                onClose={toggleDrawer("right", false)}
                className={classes.Drawer}
                >
                {sideDrawerList("right")}
            </Drawer>
        </div>
    )
}

export default SideDrawer
